.btn {
    display: inline-block;
    vertical-align: top;
    padding: 19px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    transition: all .25s;

    @media #{$xs-max} {
        font-size: 13px;
    }

    &:hover {
        text-decoration: none;
    }
    
    &.btn-primary {
        background: url(../images/btn-bg.svg) center;
        background-size: cover;
        color: $white;
        padding: 0;
        min-width: 214px;
        height: 99px;
        line-height: 99px;
        position: relative;
        border: none;

        @media #{$xs-max} {
            min-width: 174px;
            height: 80px;
            line-height: 80px;
        }

        &:hover {
            background: url(../images/btn-bg-hover.svg);
            background-size: cover;
        }

        &:focus {
            outline: none;
        }
    }

    &.btn-default {
        background: url(../images/btn-white.svg);
        background-size: cover;
        height: 60px;
        width: 170px;

        @media #{$xs-max} {
            width: 150px;
            height: 53px;
            padding: 17px;
        }

        span {
            background: linear-gradient(to top,#f36d29,#fbb03b);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        &:hover {
            background: url(../images/btn-white-hover.svg);
            background-size: cover;
            color: $white;

            span {
                background: none;
                -webkit-text-fill-color: inherit;
                color: $white;
            }
        }

    }
}

.mobile-menu-btn {
    margin: 0 auto;
    width: 30px;
    height: 12px;
    z-index: 9999;
    cursor: pointer;
    position: relative;
    transition: all .2s linear;

    @media #{$xs-max} {
        margin: 0;
        padding: 15px 0;
    }

    &:before, span {
        background: $custom-black;
        display: inline-block;
        vertical-align: top;
        height: 3px;
        width: 100%;
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
        transition: all .2s linear;

        @media #{$xs-max} {
            background: $orange;
            margin-top: 10px;
        }
    }

    &:before {
        content: '';
        top: 0;
    }

    span {
        width: 50%;
    }
}

.open-menu {
    .mobile-menu-btn {
        width: 16px;
        height: 16px;

        &:before,
        span {
            background: $orange;
            transform: rotate(45deg);
            top: 10px;
            left: 0;
            right: 15%;
            height: 2px;
        }

        span {
            transform: rotate(-45deg);
            width: 100%;
        }
    }
}

.btn-scroll {
    display: block;
    transform: rotate(-270deg);
    color: $white;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;

    &:hover {
        text-decoration: none;
        color: $white;
        -webkit-animation: float 2s linear infinite;
        animation: float 2s linear infinite;
    }

    img {
        transform: rotate(-90deg);
    }
}

.btn-play {
    display: block;
}