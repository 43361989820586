.row {
    display: flex;

    @media #{$sm-max} {
        flex-direction: column;
    }

    .col {
        width: 50%;

        @media #{$sm-max}  {
        	width: 100%;
        }
    }
}

.d-flex {
    display: flex;
}