.sidebar {
    display: flex;
    height: 100vh;
    transform: translateX(-295px);
    transition: all .5s;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;

    ul {
        margin: 0;

        li {
            list-style: none;
        }
    }

    .logo-holder {
        padding: 40px;

        @media #{$xs-max} {
            padding: 40px 25px 25px;
        }
    }

    .logo {
        display: inline-block;
        vertical-align: top;
    }

    .block-scroll {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        height: calc(100vh - 234px);
        padding: 0;

        @media #{$xs-max} {
            height: calc(100vh - 188px);
        }
    }

    .block-left {
        background: $sidebar-color;
        width: 295px;

        @media #{$xs-max} {
            width: 100%;
        }
    }

    .block-right {
        width: 60px;
        padding: 50px 7px;
        text-align: center;
        background: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        position: relative;

        @media #{$xs-max} {
            background: none;
            box-shadow: none;
            display: none;
        }

        .logo-holder {
            padding: 50px 0;

            @media #{$xs-max} {
                display: none;
            }
        }
    }

    .social {
        font-size: 10px;
        text-transform: uppercase;
        padding: 40px;

        @media #{$xs-max} {
            padding: 25px;
        }

        a {
            display: block;
            color: #424242;
            padding: 2px 0;
            width: 73px;
            text-align: center;

            &:hover {
                text-decoration: none;
                color: #424242;
                background: url(../images/btn-linkedin.svg);
                background-size: cover;
            }
        }

        li {
            display: inline-block;
            vertical-align: top;

            &:last-child {
                margin-left: 20px;
            }
        }
    }

    .block-contact {
        background: linear-gradient(to top,#f36d29,#fbb03b);
        text-transform: uppercase;
        height: 257px;
        width: 100%;
        text-align: center;
        padding: 89px 0;
        position: absolute;
        bottom: 0;
        left: 0;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }

        @media #{$xs-max} {
            display: none;
        }

        @media all and(max-height: 400px) {
            display: none;
        }

        .text {
            display: block;
            transform: rotate(-90deg);
            font-size: 12px;
            letter-spacing: 1px;
            color: $white;
            white-space: nowrap;
            margin-top: 35px;
        }

        img {
            margin-top: 40px;
        }
    }
}

.open-menu {
    .sidebar {
        transform: translateX(0);

        @media #{$xs-max} {
            width: 100%;
        }
    }

    .block-right {
        background: none;
        box-shadow: none;

        @media #{$xs-max} {
            display: none;
        }

        .logo-holder,
        .block-contact {
            display: none;
        }
    }
}