.modal {

	.modal-dialog {
		pointer-events: auto;
		position: static;
	}

	.modal-body {
		padding: 44px;

		@media #{$xs-max} {
			padding: 20px;
		}
	}

	.modal-dialog {
		
		@media #{$xs-max} {

		}
	}

	.modal-content {
		position: static;
	}

	.title-modal {
		font-size: 36px;
		text-transform: none;
		padding-left: 0;
		margin-bottom: 43px;
		letter-spacing: normal;

		@media #{$xs-max} {
			margin-bottom: 20px;
            font-size: 30px;
		}

		&:after {
			position: static;
			display: block;
		}
	}

	.close {
		position: absolute;
		right: 18px;
		top: 18px;
		background: none;
		border: none;
		cursor: pointer;
		z-index: 100;
	}

	&.fade{
		transition: none;
	}

	&.show {
		.modal-dialog {

			@media #{$xs-max} {
				transform: none;
			}
		}
	}
}

.modal-open {
	overflow: hidden !important;
	height: 100% !important;
}

.modal-full {

	@media (min-width: 576px) {
		.modal-dialog { max-width: 1300px; }
	}

	//.modal-dialog {
	//	position: fixed;
	//	margin: 0;
	//	width: 100%;
	//	height: 100%;
	//	padding: 0;
	//}
    //
	//.modal-content {
	//	position: absolute;
	//	top: 0;
	//	right: 0;
	//	bottom: 0;
	//	left: 0;
	//	border: 2px solid #3c7dcf;
	//	border-radius: 0;
	//	box-shadow: none;
	//}
    //
	//.modal-header {
	//	position: absolute;
	//	top: 0;
	//	right: 0;
	//	left: 0;
	//	height: 50px;
	//	padding: 10px;
	//	background: #6598d9;
	//	border: 0;
	//}
    //
	//.modal-title {
	//	font-weight: 300;
	//	font-size: 2em;
	//	color: #fff;
	//	line-height: 30px;
	//}
    //
	//.modal-body {
	//	position: absolute;
	//	top: 50px;
	//	bottom: 60px;
	//	width: 100%;
	//	font-weight: 300;
	//	overflow: auto;
	//}
    //
	//.modal-footer {
	//	position: absolute;
	//	right: 0;
	//	bottom: 0;
	//	left: 0;
	//	height: 60px;
	//	padding: 10px;
	//	background: #f1f3f5;
	//}

	.back-btn {
		width: 30px;
		height: 30px;
		background: none;
		border: none;
		margin-bottom: 20px;
		cursor: pointer;
		position: relative;
		padding: 0;
		z-index: 1000;

		@media #{$sm-max}  {
			margin-bottom: 20px;
		}

		@media #{$xs-max}  {
			margin-bottom: 10px;
		}
	}
}

.project-modal {
	.container {
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	img {
        max-width: 100%;
    }

	.block-project {
		.col {
			flex-direction: column;
		}
	}

	.img-holder {
        // margin: 0 auto;
        // max-width: 664px;
        // margin: 40px auto 0;
        // height: 550px;
        height: 550px;
		display: flex;
		align-items: center;

		@media #{$xs-max} {
			height: 250px;
			margin-top: 30px;
		}

		img {
			margin: 0 auto;
			max-height: 476px;
			width: auto;

			@media #{$md-max} {
				max-height: 400px;
			}

			@media #{$xs-max}  {
				max-height: 250px;
			}
		}
    }

    .text-holder {
        padding-left: 90px;

        @media #{$sm-max} {
        	padding-left: 0;
        }
    }
}

.success-block {
	text-align: center;

	h1 {
		text-transform: capitalize;
		margin: 11px 0 0;
	}
}