.faa-float.animated, .faa-float.animated-hover:hover, .faa-parent.animated-hover:hover>.faa-float {
    -webkit-animation: float 2s linear infinite;
    animation: float 2s linear infinite;
}

/* FLOAT */

@-webkit-keyframes float {
    0% {
        -webkit-transform: translateY(0) rotate(-270deg);
        transform: translateY(0) rotate(-270deg);
    }

    50% {
        -webkit-transform: translateY(-6px) rotate(-270deg);
        transform: translateY(-6px) rotate(-270deg);
    }

    100% {
        -webkit-transform: translateY(0) rotate(-270deg);
        transform: translateY(0) rotate(-270deg);
    }
}

@keyframes float {
    0% {
        -webkit-transform: translateY(0) rotate(-270deg);
        transform: translateY(0) rotate(-270deg);
    }

    50% {
        -webkit-transform: translateY(-6px) rotate(-270deg);
        transform: translateY(-6px) rotate(-270deg);
    }

    100% {
        -webkit-transform: translateY(0) rotate(-270deg);
        transform: translateY(0) rotate(-270deg);
    }
}