.service-page {
    background: $light-bg;

    .sidebar-services {
        float: left;
        width: 322px;
        margin-right: 128px;
        margin-top: 15px;

        @media #{$md-max} {
            width: 254px;
            margin-right: 30px;
        }

        @media #{$sm-max} {
            float: none;
            width: 100%;
        }
    }

    .content {
        overflow: hidden;
        padding: 15px;

        @media #{$xm-max} {
            padding: 15px 0;
        }
    }
}
