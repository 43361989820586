.social {
    padding: 0;
    margin: 0;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    li {
        list-style: none;
    }

    a {
        color: #bbbbbb;
        transition: all .25s;

        &:hover {
            color: $orange;
            text-decoration: none;
        }
    }
}