.form {
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #1f1f1f;
	}
	::-moz-placeholder { /* Firefox 19+ */
		color: #1f1f1f;
	}
	:-ms-input-placeholder { /* IE 10+ */
		color: #1f1f1f;
	}
	:-moz-placeholder { /* Firefox 18- */
		color: #1f1f1f;
	}

	.form-group {
		position: relative;
		margin-bottom: 20px;
		// display: inline-block;
		// vertical-align: top;

		@media #{$xs-max} {
			margin-bottom: 10px;
		}
	}

	label.has-error {
		font-size: 10px;
		color: $orange-dark;
		margin: 0;
	}

	.has-success,
	.has-error {
		.form-control {
			box-shadow: none;

			&:focus {
				box-shadow: none; 
			}
		}
	}

	.has-error {
		.form-control {
			border-color: $orange-dark;
			color: $black;
		}
	}

	.form-control {
		height: 60px;
		padding: 0 18px;
		font-size: 1.125rem;
		box-shadow: none;
		border-radius: 6px;
		border: 1px solid $border-input-color;
		position: relative;
		top: 0;
		width: 100%;

		@media #{$xs-max} {
			height: 50px;
		}

		&:focus {
			outline: none;
			box-shadow: none;
			border-color: $primary-color;

			& ~ .description {
				top: -10px;
				font-size: 12px;
				color: $orange-dark;
				background: $white;
				left: 18px;
				font-family: $roboto-slab;
			}
		}
	}

	.description {
		color: $black;
		font-family: $roboto;
		font-size: 14px;
		font-weight: normal;
		position: absolute;
		pointer-events: none;
		left: 18px;
		top: 20px;
		padding: 0 10px;
		margin: 0;
		z-index: 10;
		transition: 0.2s ease all;

		@media #{$xs-max} {
			top: 15px;
		}
	}

	input:-webkit-autofill {
	    background-color: $white !important;
	}

	.btn-holder {
		margin: -10px 0 0;
	}

	.has-success,
	.has-error {
		.form-control {
			box-shadow: none;
		}

		.description {
			top: -10px;
			left: 18px;
			font-size: 12px;
			color: #646464;
			background: $white;
		}

		&.form-group {
			&:after {
				opacity: 0;
			}
		}
	}

	// .has-success {
	// 	.form-control {
	// 		border-color: $primary-color;
	// 	}
	// }

	/* active state */
	input:focus ~ .bar:before,
	input:focus ~ .bar:after {
		width: 50%;
	}

	// .btn-holder {
	// 	padding-top: 10px;
	// }

}

.contact-form {

	textarea.form-control {
		height: 160px;
		padding-top: 18px;
		padding-bottom: 18px;
	}

	.optional-field {
		color: #646464;
	}
}