.main-menu {
    padding: 0;

    li {
        text-transform: uppercase;
        font-weight: 600;

        &:not(.active-link) {
            .active,
            .sub-active {
                background: linear-gradient(to top, #f36d29, #fbb03b);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }  
    }

    a {
        display: inline-block;
        padding: 20px 10px 20px 40px;
        //border-left: 4px solid transparent;
        color: $text-color;
        transition: all .25s;

        @media #{$xs-max} {
            padding: 10px 10px 10px 25px;
        }

        &:hover {
            text-decoration: none;
            background: linear-gradient(to top, #f36d29, #fbb03b);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    // .active{
    //     background: linear-gradient(to top, #f36d29, #fbb03b);
    //     -webkit-background-clip: text;
    //     -webkit-text-fill-color: transparent;
    //     position: relative;
    // }

    ul {
        padding: 0 0 0 40px;
        font-size: 12px;
        position: relative;

        @media #{$xs-max} {
            padding-left: 25px;
        }

        li {
            text-transform: none;
            font-weight: 400;

            &:not(.active-link){
                display: none;
            }
        }

        .blockchain-title {
            padding: 20px 0 10px;
            display: block;
            // font-weight: 600;

            &.active,
            &.sub-active {
                background: none;
                -webkit-text-fill-color: black;
            }

            @media #{$xs-max} {
                padding: 10px 0 5px;
            }
        }

        a {
            padding: 10px 0;

            @media #{$xs-max} {
                padding: 5px 0;
            }

            &.blockchain {
                padding-left: 20px;
            }
        }

        .hide {
            position: absolute;
            top: 64px;
            opacity: 0;

            &.first {
                opacity: 1;
                position: static;

                //&:not(.active) {
                //    opacity: 0;
                //}
            }

            &.active {
                opacity: 1;
            }
        }
    }

    .sub-menu-holder {
        .active-link {
            text-transform: uppercase;
            position: relative;
            font-size: 16px;
            font-weight: 600;
            margin-left: -40px; 

            @media #{$xs-max} {
                margin-left: -25px;
            }

            a {
                padding: 20px 10px 20px 40px;

                @media #{$xs-max} {
                    padding: 10px 10px 10px 25px;
                }
            }
        }
    }
}

.home-page {
    .sub-menu-holder {
        .active-link {
            background: #e5e5e5;
            font-size: 16px;
            font-weight: 600;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 4px;
                height: 100%;
                background: linear-gradient(to top, #f36d29, #fbb03b);
            }
        }

    }
}

// #fp-nav {
//     position: fixed;
//     z-index: 1000;
//     opacity: 1;
//     margin: 0 !important;
//     font: 10px/13px 'Sweet Sans Medium', Arial, Helvetica, sans-serif;
//     text-transform: uppercase;
//     top: 0;
//     right: 0;
//     width: 20%;
//     padding: 46px 23px 0;
// }