.contacts-page {
    &.all-cities {
        animation: opacity 2s linear infinite;
        background: url(../images/contact-us/map-world/world-1860x768.jpg) no-repeat;
        background-size: cover;

        @media #{$md-max} {
            background: url(../images/contact-us/map-world/world-1200x768.jpg) no-repeat right;
            background-size: cover;
        }

        @media #{$xs-max} {
            background: none;
        }

        .map-world {
            @media #{$xs-max} {
                display: block;
            }
        }

        .map-kharkiv,
        .map-kiev,
        .map-vancouver,
        .map-tallin {
            display: none;
        }
    }

    &.kharkiv {
        background: url(../images/contact-us/kharkiv/kh-1860x768.jpg) no-repeat center;
        background-size: cover;

        @media #{$md-max} {
            background: url(../images/contact-us/kharkiv/kh-1200x768.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$sm-max} {
            background: url(../images/contact-us/kharkiv/kh-ipad.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$xs-max} {
            background: none;
        }

        .map-kharkiv {
            @media #{$xs-max} {
                display: block;
            }
        }

        .map-world,
        .map-kiev,
        .map-vancouver,
        .map-tallin {
            display: none;
        }
    }

    &.kiev {
        background: url(../images/contact-us/kiyv/kiev-1860x768.jpg) no-repeat center;
        background-size: cover;

        @media #{$md-max} {
            background: url(../images/contact-us/kiyv/kiev-1200x768.jpg) no-repeat right;
            background-size: cover;
        }

        @media #{$sm-max} {
            background: url(../images/contact-us/kiyv/kiev-ipad.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$xs-max} {
            background: none;
        }

        .map-kiev {
            @media #{$xs-max} {
                display: block;
            }
        }

        .map-world,
        .map-kharkiv,
        .map-vancouver,
        .map-tallin {
            display: none;
        }
    }

    &.tallin {
        background: url(../images/contact-us/tallin/tallin-1860x768.jpg) no-repeat center;
        background-size: cover;

        @media #{$md-max} {
            background: url(../images/contact-us/tallin/tallin-1200x768.jpg) no-repeat center;
            background-size: cover;
        }

         @media #{$sm-max} {
            background: url(../images/contact-us/tallin/tallin-ipad.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$xs-max} {
            background: none;
        }

        .map-tallin {
            @media #{$xs-max} {
                display: block;
            }
        }

        .map-world,
        .map-kharkiv,
        .map-vancouver,
        .map-kiev {
            display: none;
        }
    }

    &.vancouver {
        background: url(../images/contact-us/vancouver/vancouver-1860x768.jpg) no-repeat center;
        background-size: cover;

        @media #{$md-max} {
            background: url(../images/contact-us/vancouver/vancouver-1200x768.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$sm-max} {
            background: url(../images/contact-us/vancouver/vancouver-ipad.jpg) no-repeat center;
            background-size: cover;
        }

        @media #{$xs-max} {
            background: none;
        }

        .map-vancouver {
            @media #{$xs-max} {
                display: block;
            }
        }

        .map-world,
        .map-kharkiv,
        .map-kiev,
        .map-tallin {
            display: none;
        }
    }
   
    .block-contacts {
        background: rgba(255, 255, 255, .85);
        width: 55%;
        margin: 0 -40px;
        padding: 95px 20px 20px 16%; 
        height: 100vh;
        line-height: 1.57;
        font-family: $roboto;

        @media #{$md-max} {
            width: 60%;
            padding-left: 10%;
        }

        @media #{$sm-max} {
            padding-top: 30px;
        }

         @media all and (min-width: 1200px) and (max-height: 768px) {
             height: auto;
         }

        @media #{$xs-max} {
            padding: 30px 20px;
            width: 100%;
            margin: 0;
            height: auto;
            min-height: 500px;
        }

        p {
            margin-bottom: 30px;

            @media #{$xs-max} {
                margin-bottom: 15px;
            }
        }

        .btn {
            @media #{$sm-max} {
                min-width: 180px;
                height: 84px;
                line-height: 84px;
            }
        }

        .btn-holder {
            .btn {
                margin-left: -20px;
                margin-top: -15px;
            }
        }
    }

    h1 {
        font-family: $roboto-slab;
    }

    h3 {
        font-size: 36px;
        margin-bottom: 44px;

        @media #{$sm-max} {
            margin-bottom: 20px;
        }

        @media #{$xs-max} {
            font-size: 20px;
            margin-bottom: 20px;
        }
    }

    .country-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .info-list {
            width: 57%;

            @media #{$xs-max} {
                width: 100%;
            }
        }

        .info-link {
            width: 43%;
            padding-left: 20px;

            @media #{$sm-max} {
                padding-left: 10px;
            }

            @media #{$xs-max} {
                width: 100%;
                padding: 0;
            }
        }

        .social {
            margin-bottom: 25px;
            font-weight: 600;
            font-size: 11px;
            padding: 10px 20px 0;

            @media #{$sm-max} {
                padding: 10px 5px 0;
            }

            @media #{$xs-max} {
                justify-content: initial;
                margin-bottom: 10px;
                padding-top: 0;
            }

            li {
                &:first-child {
                    margin-right: 10px;
                }
            }

            a {
                color: $black;

                &:hover {
                    background: linear-gradient(to top,#f36d29,#fbb03b);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }

    .country-info-list {
        padding: 0;

        li {
            font-size: 14px;
            color: $black;
            margin-bottom: 10px;
            list-style: none;
            position: relative;
            padding-left: 20px;

            &:before {
                content: '';
                background: url('../images/geo.svg') no-repeat;
                position: absolute;
                left: 0;
                top: 3px;
                width: 13px;
                height: 18px;
            }

            &.info-mail {
                &:before {
                    background: url('../images/mail.svg') no-repeat;
                    top: 5px;
                    width: 15px;
                    height: 11px;
                }
            }

            &.info-phone {
                font-weight: 600;

                &:before {
                    background: url('../images/phone.svg') no-repeat;
                    width: 14px;
                    height: 13px;
                }
            }

            &:first-child {
                margin-bottom: 30px;

                @media #{$xs-max} {
                    margin-bottom: 15px;
                }
            }

            a {
                color: $black;
            }
        }
    }

    .map-img-holder {
        @media #{$xs-max} {
            height: 350px;
            overflow: hidden;
        }

        @media #{$xm-max} {
            height: 200px;
        }

        img {
            width: 100%;

            @media #{$xs-max} {
                object-fit: cover;
                height: 100%;
            }
        }
    }
}
