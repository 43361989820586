.card-item-holder {
    font-size: 0;

    .card-item {
        display: inline-block;
        vertical-align: top;
        width: 33.3%;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 5px;
        padding: 35px;
        height: 268px;
        border-radius: 2px;
        overflow: hidden;
        transition: background-image .25s;
        border-bottom: 6px solid transparent;

        @media #{$lg-max} {
            padding: 20px 30px;
            margin: 0;
            // height: 235px;
        }

        @media #{$md-max} {
            padding: 20px;
            height: 285px;
        }

        @media #{$sm-max} {
            height: 325px;
        }

        @media #{$xs-max} {
            height: auto;
        }

        p {
            margin: 0;
        }

        .img-hover {
            display: none;
        }

        &:hover {
            background-image: linear-gradient(to top, #f36d29, #fbb03b);
            box-shadow: 0 13px 50px 0 #f78e32;
            color: $white;
            border-color: $white;

            h4 {
                color: $white;
            }

            .img {
                display: none;
            }

            .img-hover {
                display: inline-block;
                vertical-align: top;
            }
        }

        @media #{$xs-max} {
            width: 100%;
        }

        h4 {
            font-weight: 400;
            margin-bottom: 10px;
        }

        .icon {
            margin-bottom: 25px;
            height: 46px;

            @media #{$sm-max} {
                margin-bottom: 15px;
            }
        }

        &.big {
            text-align: center;
            width: 100%;
            font-size: 18px;

            &:hover {
                background: $white;
                box-shadow: none;
                color: $black;

                h4 {
                    color: $black;
                }
            }

            .icon {
                height: 80px;
                margin-bottom: 27px;
            }
        }
    }
}