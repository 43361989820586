.block-rating  {
	.starbox {
		float: left;

		.stars {
			.star_holder {
				.star {
					width: 17px;
					height: 16px;
					background-size: cover;
				}
			}
		}

		.colorbar {
			background-image: linear-gradient(to top,#f36d29,#fbb03b);
		}
	}

	.rating-value {
		float: left;
		margin: 0 9px 0 20px;
		font-weight: 600;
		color: #6a6a6a;
	}
}