.justify-center {
	justify-content: center;
}

.text-center {
    text-align: center;
}

.align-center {
    align-items: center;
}

.visible-xs {
    display: none;

    @media #{$xs-max} {
        display: block;
    }
}

.hidden-xs {
    display: block;

    @media #{$xs-max} {
        display: none;
    }
}

.img-responsive {
    max-width: 100%;
}

a,
button,
img {
    &:focus,
    &:active {
        outline: none;
    }
}

.block-scroll {
    height: 422px;
    overflow-y: auto;
    padding-right: 20px;
}

.main-text {
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;

    @media #{$xs-max} {
        font-size: 16px;
    }
}