.home-page {
    .main-menu {
        ul {
           li {
                display: block;
            } 
        } 
    }
}

.brand-section {
    background: $black;
    color: $white;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    min-height: 550px;
    display: flex;
    align-items: center;
    overflow: hidden;

    @media #{$xs-max} {
        font-size: 16px;
        background: url(../images/brand-img.jpg) no-repeat;
        background-size: cover;
    }

    .container {
        position: relative;
        display: flex;
        height: 100%;
        align-items: center;
        z-index: 100;
    }

    h1 {
        mark {
            background: none;
            color: $orange;
            text-decoration: underline;
        }
    }

    p {
        margin-bottom: 30px;

        @media #{$xs-max} {
            margin-bottom: 15px;
        }
    }

    .text-holder {
        padding-right: 100px;
        max-width: 80%;

        @media #{$xs-max} {
            padding-right: 0;
            max-width: 100%;
        }

        .btn {
            margin-left: -20px;
        }
    }

    .video-holder {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        @media #{$xs-max} {
            display: none;
        }

        video {
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
        }
    }

    .btn-holder {
        position: absolute;
        top: 145px;
        right: 160px;
        height: 100%;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        display: none;

        @media #{$md-max} {
            top: 60px;
            right: 60px;
        }

        @media #{$xs-max} {
            display: none;
        }
    }
}

.technologies-section {
    color: $grey-text;
    font-size: 15px;
    letter-spacing: 0.2px;
    padding-top: 30px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$xs-max} {
        display: block;
        padding-top: 50px;
    }

    .item-holder {
        display: flex;
        // margin-bottom: 40px;
        padding-left: 120px;

        @media #{$lg-max} {
            padding-left: 60px;
        }

        @media #{$md-max} {
            padding-left: 0;
        }

        @media #{$xs-max} {
            flex-direction: column;
        }

        .img-holder {
            width: 237px;
            margin-bottom: 20px;
            text-align: center;

            @media #{$xs-max} {
                margin: 0 auto;
                width: 180px;
            }

            @media #{$xm-max} {
                width: 135px;
            }

            img {
                max-width: 100%;
            }
        }
    }

    .img {
        text-align: center;
        margin-right: 100px;

        @media #{$md-max} {
            margin-right: 30px;
        }

    }

    .img-name {
        font-size: 48px;
        font-weight: 600;
        letter-spacing: 2px;
        color: #d5d5d5;

        @media #{$sm-max} {
            font-size: 40px;
        }

        @media #{$xs-max} {
            font-size: 30px;
        }

        @media #{$xm-max} {
            font-size: 25px;
        }
    }

    p {
        margin-bottom: 12px;
    }

    .list-info {
        padding: 0;

        li {
            display: inline-block;
            vertical-align: top;
            margin: 0 16px 0 0;

            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                background: url(../images/marker-orange.svg) no-repeat;
                width: 11px;
                height: 11px;
                margin-right: 7px;
                margin-top: -2px;
            }
        }
    }

    .block-info {
        .description {
            font-size: 14px;
            letter-spacing: 0.2px;
            color: #878787;
        }
    }

    &.pink {
        h2 {
            &:after {
                background-image: linear-gradient(to left, #651297, #441464);
            }
        }

        .list-info {
            li {
                &:before {
                    background: url(../images/marker-pink.svg) no-repeat;
                }
            }
        }

        .block-grey {
            blockquote {
                &:before {
                    background: url(../images/left-quote-pink.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    &.green {
        h2 {
            &:after {
                background-image: linear-gradient(to left, #4db25b, #006837);
            }
        }

        .list-info {
            li {
                &:before {
                    background: url(../images/marker-green.svg) no-repeat;
                }
            }
        }

        .block-grey {
            blockquote {
                &:before {
                    background: url(../images/left-quote-green.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    &.blue {
        h2 {
            &:after {
                background-image: linear-gradient(to left, rgba(34, 221, 252, 0.98), #1b85e4);
            }
        }

        .list-info {
            li {
                &:before {
                    background: url(../images/marker-blue.svg) no-repeat;
                }
            }
        }

        .block-grey {
            blockquote {
                &:before {
                    background: url(../images/left-quote-blue.svg) no-repeat;
                    background-size: cover;
                }
            }
        }
    }
}

.block-grey {
    background: #ebebeb;
    color: $black;
    font-size: 16px;
    padding: 44px 0;

    @media #{$xs-max} {
        padding: 55px 0 25px;
        font-size: 14px;
    }

    .img-holder {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        border-radius: 50%;
        overflow: hidden;

        @media #{$sm-max} {
            margin-right: 10px;
        }

        img {
            max-width: 100%;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .holder {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media #{$xs-max} {
            flex-direction: column;
            justify-content: normal;
        }
    }

    blockquote {
        margin: 0;
        padding-left: 75px;
        padding-right: 20px;
        position: relative;
        max-width: 75%;

        @media #{$xs-max} {
            padding: 0;
            margin-bottom: 15px;
            max-width: 100%;
        }

        &:before {
            content: '';
            display: block;
            background: url(../images/left-quote-orange.svg) no-repeat;
            background-size: cover;
            width: 58px;
            height: 41px;
            position: absolute;
            left: 0;
            top: -20px;

            @media #{$xs-max} {
                top: -38px;
                width: 40px;
                height: 28px;
            }
        }
    }

    .block-info {
        display: flex;
        align-items: center;
        min-width: 240px;

        @media #{$sm-max} {
            min-width: 230px;
        }

        @media #{$xs-max} {
            width: 100%;
        }
    }
}

.guarantees-section {
    background: $light-bg;

    h4 {
        text-transform: uppercase;
        line-height: 1.78;
        letter-spacing: 0.2px;
        color: #3e3e3e;
        margin-bottom: 32px;

        @media #{$lg-max} {
            margin-bottom: 20px;
        }

        @media #{$sm-max} {
            margin-bottom: 16px;
        }
    }

    .text-holder {
        font-size: 18px;
        font-weight: 300;
        line-height: 1.89;
        letter-spacing: 0.2px;
        padding: 60px;
        box-shadow: 0 20px 30px 0 rgba(206, 206, 206, 0.5);
        border-radius: 6px;
        position: relative;
        overflow: hidden;

        @media #{$md-max} {
            padding: 40px;
        }

        @media #{$xs-max} {
            padding: 20px;
            font-size: 16px;
            line-height: 1.5;
        }

        .title {
            margin-bottom: 18px;

            @media #{$xs-max} {
                margin-bottom: 10px;
            }

            &:after {
                content: '';
                display: block;
                height: 3px;
                background-image: linear-gradient(to right, #f36d29, #fbb03b);
                margin-top: 7px;
            }
        }
    }

    .number {
        position: absolute;
        bottom: 0;
        right: -30px;
        font-size: 260px;
        line-height: 190px;
        font-weight: bold;
        background: linear-gradient(to top, #f36d29, #fbb03b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        opacity: .4;
        z-index: -1;

        @media #{$xs-max} {
            right: -13px;
            font-size: 150px;
            line-height: 105px;
        }
    }
}

.project-section {
    color: $white;
    position: relative;
    font-size: 15px;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    h2 {
        color: $white;

        &:after {
            background: $white;
        }
    }

    .text-holder {
        padding-left: 70px;

        @media #{$md-max} {
            padding-left: 35px;
        }

        @media #{$xs-max} {
            padding-left: 0;
        }

        h3,
        h4 {
            color: $white;
        }

        h4 {
            text-transform: uppercase;
        }

        p {
            margin-bottom: 20px;
        }
    }

    .link-more {
        display: inline-block;
        vertical-align: top;
        color: $white;
        font-size: 15px;
        font-weight: 600;
        position: relative;

        &:hover {
            text-decoration: none;
            opacity: .8;
        }
        
        &:after {
            content: '';
            display: block;
            margin-top: 5px;
            height: 2px;
            width: 60%;
            background: $white;
        }

        img {
            width: 10px;
            transform: rotate(-90deg);
            display: inline-block;
            margin-left: 15px;
        }
    }
}

.block-project {
    width: 100%;
    color: $white;

    h3,
    h4 {
       color: $white;  
    }

    h4 {
        text-transform: uppercase;
    }

    a {
        display: inline-block;
        vertical-align: top;
        margin-bottom: 30px;
        color: $white;

        @media #{$xs-max} {
            margin-bottom: 15px;
        }
    }
}

.team-section {
    font-size: 0;

    .item-team-holder {
        display: inline-block;
        vertical-align: top;
        width: 20%;
        padding: 0 10px;
        margin-bottom: 20px;

        @media #{$md-max} {
            width: 100%;
            margin-bottom: 35px;
        }

        @media #{$xm-max} {
            width: 100%;
        }
    }

    .item-team {
        font-size: 12px;
        letter-spacing: 0.2px;
        color: #999999;
        box-shadow: 0 20px 30px 0 rgba(206, 206, 206, 0.5);
        position: relative;
        border-radius: 6px;
        overflow: hidden;

        &:hover {
            .hover-block {
                opacity: 1;
            }
        }

        .title {
            display: block;
            margin-bottom: 5px;
            text-transform: uppercase;
            color: $black;
            letter-spacing: 0.7px;
        }

        .position {
            display: block;
        }

        .text {
            padding: 10px 20px;
            height: 79px;

            @media #{$lg-max} {
                padding: 10px 15px;
            }
        }

        p {
            margin: 0;
        }
    }

    .img-holder {
        height: 200px;
        overflow-y: hidden;

        @media #{$md-max} {
            height: 240px;
        }

        @media #{$sm-max} {
            height: 240px;
        }

        @media #{$xm-max} {
            height: 300px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .hover-block {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        color: #bbbbbb;
        padding: 20px;
        font-size: 12px;
        transition: opacity .25s;

        @media #{$lg-max} {
            padding: 15px;
        }

        .title {
            color: $white;
        }

        .text {
            padding: 0;
            height: auto;
        }

        .position {
            height: 44px;

            @media #{$lg-max} {
                height: 40px;
            }
        }

        p {
            margin-bottom: 20px;

            @media #{$lg-max} {
                margin-bottom: 10px;
            }
        }

        .social {
            position: absolute;
            bottom: 20px;

            @media #{$lg-max} {
                bottom: 15px;
            }
        }
    }
}

.link-more {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.1px;
    color: #969696;
}

.testimonials-section {
    background: $light-bg;

    h2 {
        margin-bottom: 30px;
    }

    .clients-item-holder {
        padding: 60px 40px 77px;

        @media #{$lg-max} {
            padding: 60px 17px 40px;
        }
    }

    .testimonials-carousel .owl-item.active:nth-of-type(odd) .clients-item-holder {
        padding-right: 20px;
    }

    .testimonials-carousel .owl-item.active:nth-of-type(even) .clients-item-holder {
        padding-left: 20px;
    }

    .clients-item {
        box-shadow: 1px 42px 72px 0 rgba(126, 134, 161, 0.29);
        padding: 0 40px 40px;
        height: 471px;
        position: relative;
        font-size: 14px;
        font-weight: 300;
        border: solid 1px #f9f9f9;

        @media #{$lg-max} {
            padding: 1px 25px 40px;
            box-shadow: 0 7px 28px 0 rgba(126, 134, 161, 0.29);
        }

        @media #{$md-max} {
            height: 579px;
        }

        @media #{$sm-max} {
            font-size: 12px;
        }

        @media #{$xs-max} {
            height: 501px;
        }

        @media #{$xm-max} {
            height: 579px;
        }

        .block-top {
            margin-top: -60px;
        }

        .img-holder {
            max-width: 117px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 20px;

            img {
                max-width: 100%;
            }
        }

        .block-rating {
            position: absolute;
            right: 40px;
            top: 40px;

            @media #{$sm-max} {
                right: 25px;
            }
        }

        .inner-text {
            padding: 22px 0;
            border-top: 1px solid #e7e7e7;
            border-bottom: 1px solid #e7e7e7;
            height: 157px;
            position: relative;
            text-align: justify;

            @media #{$md-max} {
                height: 200px;
            }

            @media #{$xs-max} {
                height: auto;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 97%;
                left: 0;
                display: block;
                background: url(../images/testimonials-quotes.svg) $light-bg no-repeat;
                width: 17px;
                height: 12px;
                padding: 0 26px 0 0;
            }
        }

        .block-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$xs-max} {
                flex-direction: column;
            }
        }

        .company-logo {
            max-width: 115px;

            @media #{$xs-max} {
                max-width: 150px;
                margin: 20px 0 0;
            }
        }

        .list-info {
            font-size: 0;
            width: 68%;
            margin: 30px 0 0;
            padding: 0;

            @media #{$md-max} {
                width: 154px;
                margin-left: 10px;
            }

            @media #{$xm-max} {
                margin-top: 20px;
            }

            li {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                font-size: 13px;
                font-weight: 500;
                color: #b0b0b0;
                margin-bottom: 15px;

                @media #{$md-max} {
                    display: block;
                    width: 100%;
                }
            }

            .icon {
                float: left;
                width: 22px;
                height: 22px;
                margin: -2px 5px 0 0;
            }

            .list-info .desc {
                display: block;
                overflow: hidden;
                max-height: 38px;
            }
        }
    }

    .clients-name {
        margin-bottom: 10px;
        font-size: 24px;
        line-height: 1.1;
    }

    .position {
        margin-bottom: 10px;
        position: relative;
        color: $grey-text;
    }

    .clients-direction {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 500;
        background: linear-gradient(to top,#f36d29,#fbb03b);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .clients-company {
        font-weight: 600;
        color: $black;
    }
}

.partners-section {

    >.container {
        width: 100%;
    }

    .logo-holder {
        max-width: 600px;
        margin: 0 auto;
    }
   
    .logo {
        display: inline-block;
        vertical-align: top;
        width: 40%;
        margin: 0 10px 55px;
        max-width: 234px;

        &:last-child {
            margin-bottom: 0;
        }

        @media #{$md-max} {
            margin-bottom: 20px;
        }

        @media #{$xs-max} {
            pointer-events: none;
        }

        .img-hover {
            display: none;
        }

        &:hover {
            .img {
                display: none;
            }

            .img-hover {
                display: block;
            }
        }
    }

    .text-holder {
        padding-left: 100px;
        letter-spacing: 0.2px;
        font-weight: 400;
        line-height: 2;

        @media #{$md-max} {
            padding-left: 50px;
        }

        @media #{$sm-max} {
            padding-left: 0;
            padding-top: 20px;
        }
    }

    .footer {
        background: url(../images/rectangle.png) no-repeat;
        background-size: cover;
        padding: 300px 0 60px;
        margin: -600px 0 0;

        @media #{$lg-max} {
            margin-top: -150px;
        }

        @media #{$sm-max} {
            margin-top: -285px;
            padding: 430px 0 25px;
        }

        @media #{$xm-max} {
            margin-top: -380px;
        }
    }
}

.block-partners {
    display: flex;

    .carousel-holder {
        width: 50%;
    }
}

.test-holder {
    position: relative;

    #partners {
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
    }
}
