.about-page {
    .about-section {
        background-image: linear-gradient(to left, #FEEBCE, #FCDBCA);

        .img-holder {
            @media #{$sm-max} {
                max-width: 400px;
                margin: 0 auto;
            }
        }
    }

    .text-holder {
        padding-right: 160px;
        line-height: 2;
        letter-spacing: 0.2px;

        @media #{$md-max} {
            padding-right: 80px;
        }

        @media #{$sm-max} {
            padding:  0 0 20px;
        }
    }

    .card-item-holder {
        margin-bottom: 40px;
    }
}