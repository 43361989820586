.accordion-services {
    .card {
        border-bottom: 1px solid #eee;
    }
    .card-header {

        .btn-collapse {
            width: 100%;
            background-image: linear-gradient(to right, #f36d29, #fbb03b);
            color: $white;
            border: none;
            text-transform: uppercase;
            text-align: left;
            font-weight: 600;
            letter-spacing: 0.2px;
            padding: 18px 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media #{$xs-max} {
                padding: 5px 15px;
            }

            .close {
                display: none;
            } 

            &.collapsed {
                background: none;
                color: $black;

                .close {
                    display: block;
                }

                .open {
                    display: none;
                }
            }

            &:focus {
                outline: none;
            }
        }
    }

    .card-body {
        padding: 30px;
        font-weight: 300;
        line-height: 1.71;
        font-family: $roboto;

        @media #{$xs-max} {
            padding: 15px;
        }

        h5 {
            font-size: 14px;
            color: #898989; 
        }

        p {
            margin-bottom: 20px;

            @media #{$xs-max} {
                margin-bottom: 10px;
            }

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .collapse {
        &.show {

        }
    }
}

