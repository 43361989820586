h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 20px;

    @media #{$md-max} {
        font-size: 40px;
    }

    @media #{$sm-max} {
        font-size: 30px;
    }

    @media #{$xs-max} {
        font-size: 24px;
    }
}

h2 {
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    color: #2e2e2e;
    letter-spacing: 1.7px;
    position: relative;
    padding-left: 60px;
    margin-bottom: 60px;

    @media #{$lg-max} {
        margin-bottom: 30px;
    }

    @media #{$sm-max} {
        padding-left: 45px;
        margin-bottom: 20px;
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 20px;
        width: 51px;
        height: 4px;
        background-image: linear-gradient(to top, #f36d29, #fbb03b);

        @media #{$sm-max} {
            width: 35px;
        }

        @media #{$xs-max} {
            top: 12px;
        }
    }

    @media #{$xs-max} {
        font-size: 20px;
    }

    span {
        display: block;
        padding-left: 40px;
    }
}

h3 {
    font-size: 28px;
    font-weight: 600;
    color: #2e2e2e;

    @media #{$xs-max} {
        font-size: 18px;
    }
}

h4 {
    font-size: 18px;
    letter-spacing: 0.2px;
    color: $black;

    @media #{$sm-max} {
        font-size: 16px;
    }
}

h5 {
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    color: $black;
    margin: 0 0 6px;
}