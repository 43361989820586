.tab-content {
    position: relative;
}

.tab-nav {
    padding: 0;

    li {
        list-style: none;
        cursor: pointer;
    }

    .mobile-text {
        display: none;

        @media #{$xs-max} {
            display: block;
        }
    }

    .desktop-text {
        @media #{$xs-max} {
            display: none;
        }
    }
}

.fade-tabs .tab {
    opacity: 0;
    transition: opacity .75s;
    position: absolute;
    left: 0;
    top: 0;
}

.fade-tabs .tab.active {
    opacity: 1;
    z-index: 100;
}

.tabs-guarantees {
    display: flex;

    @media #{$sm-max} {
        flex-direction: column;
    }

    .tab-nav {
        padding-right: 200px;
        margin: 0;

        @media #{$lg-max} {
            padding-right: 100px;
        }

        @media #{$sm-max} {
            padding: 0;
        }

        li {
            margin-bottom: 30px;
            padding-right: 30px;
            color: #747474;
            font-size: 15px;
            cursor: pointer;

            @media #{$sm-max} {
                margin-bottom: 15px;
                padding-right: 0;
            }

            @media #{$xs-max} {
                margin-bottom: 5px;
            }

            &.active {
                color: $black;
                font-weight: 500;
                position: relative;

                strong {
                    background: linear-gradient(to top,#f36d29,#fbb03b);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &:after {
                        background-image: linear-gradient(to top,#f36d29,#fbb03b);
                    }
                }

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    right: -25%;
                    top: 50%;
                    width: 10px;
                    height: 11px;
                    background: url(../images/triangle.svg) no-repeat;
                    margin-top: -6px;

                    @media #{$lg-max} {
                        right: -67px;
                    }

                    @media #{$sm-max} {
                        display: none;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: auto;
                    left: 100%;
                    width: 230px;
                    height: 1px;
                    margin: -1px 0 0;
                    background-image: linear-gradient(to top, #f36d29, #fbb03b);

                    @media #{$lg-max} {
                        width: 100px;
                    }
                }
            }

            &:last-child {
                margin: 0;
            }

            strong {
                font-size: 18px;
                position: relative;

                @media #{$xs-max} {
                    font-size: 16px;
                }

                &:after {
                    content: '';
                    background: #979797;
                    display: inline-block;
                    vertical-align: middle;
                    height: 1px;
                    width: 29px;
                    margin: -2px 12px 0;

                    @media #{$xs-max} {
                        width: 15px;
                        margin: -2px 5px 0;
                    }
                }
            }
        }
    }


    .nav-holder {
        flex: 55% 0;
    }

    .tab-content {
        flex: 0 45%;

        @media #{$sm-max} {
            height: 500px;
        }

        @media #{$xs-max} {
            height: 320px;
        }
    }

    .tab,
    .text-holder,
    .text {
        height: 100%;
    }
}

.tabs-services-section {
     .tab-nav {
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.1px;
        color: #969696;
        display: flex;
        position: relative;
        background: url(../images/tab-block-img.svg) no-repeat;
        background-size: cover;
        //border: 1px solid #dfdfdf;

        @media #{$xl-max} {
            background: none;
            border: 1px solid #dfdfdf;
        }

        @media #{$xs-max} {
            border: 1px solid #dfdfdf;
        }

        //&:before,
        //&:after {
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    top: -1px;
        //    right: -12px;
        //    width: 30px;
        //    height: 103%;
        //    background: url(../images/polygon.png) no-repeat;
        //    background-size: contain;
        //
        //    @media #{$sm-max} {
        //        display: none;
        //    }
        //}
        //
        //&:after {
        //    right: auto;
        //    left: -12px;
        //    transform: rotate(-180deg);
        //    height: 105%;
        //}

        li {
            list-style: none;
            width: 25%;
            padding: 16px 10px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
            //border-right: none;
            //border-left: none;

            @media #{$lg-max} {
                padding: 12px 8px;
            }

            @media #{$xs-max} {
                font-size: 12px;
            }

            &.active {
                color: $white;
                background: url(../images/tab-bg.svg) no-repeat center;
                background-size: cover;
                z-index: 10;

                @media #{$xl-max} {
                    background-image: linear-gradient(to top, #f36d29, #fbb03b);
                }
                //border-color: $white;
                //background-image: linear-gradient(to top, #f36d29, #fbb03b);
            }
        }

    }
}

.tabs-services {
    .tab-nav {
        @media #{$sm-max} {
            display: flex;
            margin: 0 15px;
            border: 1px solid #dfdfdf;
        }

        @media #{$xm-max} {
            margin: 0;
        }

        li {
            padding: 18px 30px;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 1.1px;
            color: #969696;
            text-transform: capitalize;

            @media #{$md-max} {
                padding: 13px 15px;
                font-size: 14px;
            }

            @media #{$sm-max} {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25%;
                text-align: center;
                padding: 12px 8px;
            }

            @media #{$xs-max} {
                font-size: 12px;
            }

            @media #{$xm-max} {
                padding: 8px 5px;
            }

            &.active {
                color: $white;
                background: url(../images/service-tab-bg.svg) no-repeat center;
                background-size: cover;
            }
        }
    }

    .tab-content {
        background: $white;
        border-radius: 6px;
        box-shadow: 0 2px 40px 0 rgba(215, 215, 215, 0.5);
        overflow: hidden;
    }
}

.tabs-contacts {
    max-width: 455px;

    .tab-content {
        min-height: 420px;
    }

    .tab-nav {
        display: inline-block;
        vertical-align: top;
        padding-bottom: 42px;
        margin-bottom: 80px;
        border-bottom: 2px solid $black;
        font-size: 0;

        @media #{$sm-max} {
            margin-bottom: 40px;
            padding-bottom: 20px;
        }

        @media #{$xs-max} {
            margin-bottom: 20px;
            padding-bottom: 10px;
        }

        li {
            display: inline-block;
            vertical-align: top;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            margin-right: 25px;
            letter-spacing: 1px;

            @media #{$sm-max} {
                margin-right: 10px;
            }

            @media #{$xm-max} {
                font-size: 10px;
                margin-right: 6px;
            }

            &:last-child {
                margin: 0;
            }

            &.active {
                background: linear-gradient(to top,#f36d29,#fbb03b);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
    }
}