//fonts
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:300,400,700|Roboto:300,400,400i,500,700,900');

//bootsrap
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/transitions';

//components
@import "components/variables";
@import "components/mixins";
@import "components/normalizer";

@import 'components/header';
@import 'components/footer';
@import 'components/layout';
@import 'components/elements';
@import 'components/buttons';
@import 'components/sidebar';
@import 'components/main-menu';
@import 'components/typography';
@import 'components/animation';
@import 'components/tabs';
@import 'components/grid';
@import 'components/owl-carousel-custom';
@import 'components/rating';
@import 'components/modal-custom';
@import 'components/form';
@import 'components/gradients';
@import 'components/collapse-custom';
@import 'components/social';
@import 'components/card-items';
@import 'components/fullPage-custom';

@import 'pages/home';
@import 'pages/services';
@import 'pages/contacts';
@import 'pages/about';