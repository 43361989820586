.header {
	position: fixed;
    top: 0;
    left: 0;
    padding: 10px 0 10px 15px;
    background: #ececec;
    width: 100%;
    z-index: 1000;
}

.open-menu {
	.header {
		background: none;
	}
}