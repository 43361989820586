.owl-carousel {
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	display: none;

	.owl-stage-outer {
		overflow: hidden;
	}

	.owl-item {
		float: left;

		img {
			width: 100%;
			cursor: pointer;
		}
	}
}

.owl-nav {
	text-align: center;
	padding: 40px 0;

	@media #{$sm-max} {
		padding: 20px 0;
	}

	.owl-prev,
	.owl-next {
		cursor: pointer;
		cursor: hand;
		user-select: none;
		width: 37px;
		height: 36px;
		background: url(../images/slider-arrow-grey.svg) no-repeat;
		display: inline-block;
		vertical-align: middle;
		overflow: hidden;
		margin: 0 6px;
		border: none;

		&:hover {
			background: url(../images/slider-arrow-orange.svg) no-repeat;
			transform: rotate(-180deg);
		}

		&.disabled {
			opacity: 0.5;
			cursor: inherit;
			pointer-events: none;
		}

		span {
			display: none;
		}

		&:focus {
			outline: none;
			border: none;
		}

		&.owl-next {
			transform: rotate(-180deg);

			&:hover {
				transform: none;
			}
		}
	}
}

.disabled {
	display: none;
}

.project-carousel,
.project-modal-carousel {
	z-index: 100;
	
	.owl-nav {

		.owl-prev,
		.owl-next {
			background: url(../images/slider-arrow.svg) no-repeat;

			&:hover {
				background: url(../images/slider-arrow-white.svg) no-repeat;
			}
		}

		.owl-prev {
			&:hover {
				transform: none;
			}
		}

		.owl-next {
			&:hover {
				transform: rotate(-180deg);
			}
		}
	}
}

.testimonials-carousel {
	margin: 0 -40px;

	@media #{$lg-max} {
		margin: 0 -10px;
	}

	.owl-nav {
		padding: 0;
		margin-top: -40px;
		position: relative;
		z-index: 100;

		@media #{$lg-max} {
			margin-top: -15px;
		}
	}
}

.partners-carousel {
	text-align: center;
	z-index: 100;
}

.team-carousel {
	.owl-nav {
		margin-top: -40px;
		position: relative;
	}

	.owl-item {
		.item-team-holder {
			width: 100%;
		}
	}
}

.partners-carousel {
	.owl-nav {
		padding-bottom: 0;
	}
}