.grey-gradient-bg {
    background-image: linear-gradient(to right, #15232c, #cacaca);

    &:after {
        content: '';
        background: url(../images/project-grey-polygon.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50px;
        left: 50px;
        width: 555px;
        height: 546px;
    }
}

.green-gradient-bg {
    background-image: linear-gradient(to right, #358e59, #5ee493);
    
    &:after {
        background: url(../images/project-green-polygon.png) no-repeat;
        background-size: contain;
    }
}

.blue-gradient-bg {
    background-image: linear-gradient(to right, #328cd9, #4cd6df);

    &:after {
        background: url(../images/project-blue-polygon.png) no-repeat;
        background-size: contain;
    }
}

.gradient-bg {

    &:after {
        content: '';
        position: absolute;
        top: 50px;
        left: 50px;
        width: 555px;
        height: 546px;

        @media #{$xs-max} {
            top: 5px;
            left: 5px;
            width: 100%;
            height: 100%;
        }
    }
}