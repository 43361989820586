html {
	height: 100%;
}

body {
	height: 100%;
	position: relative;
	font-family: $default-font;
	font-size: $default-font-size;
	font-weight: $default-font-weight;
	font-style: $default-font-style;
	line-height: $default-line-height;
	color: $default-text-color;
	background-color: $body-bg;
	min-width: 320px;

	@media #{$xs-max} {
		font-size: 14px;
	}
}

.layout {
	position: relative;
	overflow: hidden;
	min-height: 100%;

	@media #{$xs-max} {
		padding-top: 10px;
	}
}

.main {
	padding-left: 60px;
	transition: all .5s;

	@media #{$xs-max} {
		padding-left: 0;
	}
}

.section {
	padding: 30px 0;
	height: 100vh;

	@media #{$md-max} {
		height: auto;
	}

	@media #{$xs-max} {
		padding-top: 50px;
	}

	@media all and (max-height: 768px) {
        height: auto;
    }
}

.last-section {
	padding-bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 768px;

	@media #{$xs-max} {
		min-height: 600px;
	}
}

.container {
	max-width: 1340px;
	margin: 0 auto;
	padding: 0 40px;
	width: 100%;

	@media #{$sm-max} {
		padding: 0 20px;
	}

	@media #{$xs-max} {
		padding: 0 15px;
	}
}

.open-menu {

	@media #{$xs-max} {
		overflow: hidden;
	}

	.main {
		transform: translateX(295px);
		padding: 0;
	}
}

