.footer {
    position: relative;
    color: $white;
    padding: 20px 0 30px;
    background-image: linear-gradient(to right, #f36d29, #fbb03b);

    h3 {
       color: $white;
    }

    .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

